import fastDeepEqual from 'fast-deep-equal/es6'
import { useEffect, useState } from 'react'
/**
 * Stabilizes an object using deep equals comparisons.
 * If found different, will return a new
 * object. Otherwise, returns the original object reference.
 *
 * Useful to stabilize useEffect dependencies that depend upon the values of a large, complex object
 * that is likely to be re-created (e.g. passed in as props)
 *
 * Uses fast-deep-equal (w/ es6 support) for object comparison:
 * https://github.com/epoberezkin/fast-deep-equal
 */
export function usePreserveDeepEquals<TObject>(inputObject: TObject): TObject {
  const [stableObject, setStableObject] = useState<TObject>(inputObject)
  useEffect(() => {
    setStableObject((current) => {
      if (!fastDeepEqual(current, inputObject)) {
        return inputObject
      }
      return current
    })
  }, [inputObject])
  return stableObject
}

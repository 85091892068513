import React, { useEffect, useMemo, useState } from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useMessage } from '../use-message'

const getIframeSrc = ({
  amexAccepted,
  toastwebBaseUri,
  shortUrl,
  color
}: {
  amexAccepted: boolean
  toastwebBaseUri: string
  shortUrl: string
  /**
   * Hex code string for css color variable
   */
  color?: string
}) => {
  const iframeSrc =
    process.env.CREDIT_CARD_IFRAME_URI ||
    `${toastwebBaseUri}/${shortUrl}/v3/ccform`

  const iframeUrl = new URL(iframeSrc)
  if (color) {
    iframeUrl.searchParams.set('color', color)
  }
  if (amexAccepted) {
    iframeUrl.searchParams.set('amexAccepted', 'true')
  }

  return iframeUrl.toString()
}

export type CreditCardProps = {
  amexAccepted: boolean
  /**
   * @deprecated
   */
  toastwebBaseUri: string
  /**
   * @deprecated
   */
  shortUrl: string
  /**
   * Takes precedence if present
   */
  iframeUrl?: string
  onChange(encryptedCardData: {
    encryptionKeyId: string
    cardFirst6: string
    cardLast4: string
    zipCode: string
    expMonth: string
    expYear: string
  }): void
  onError(): void
  /**
   *  Hex-formatted string, including the "#"
   *  symbol. (e.g. #ABC123)
   */
  color?: string
  /**
   * We need to explicitly delegate visibility
   * behavior to this component to discourage unsafe practices,
   * such as wrapping in a hidden element.
   *
   * Attempts to do so will conflict
   * with the current way that the iframe height is dynamically controlled by the
   * iframe component.
   */
  isVisible: boolean
}

export const CreditCard = ({
  amexAccepted,
  toastwebBaseUri,
  shortUrl,
  iframeUrl,
  onChange,
  onError,
  color,
  isVisible
}: CreditCardProps) => {
  const [height, setHeight] = useState(0)
  const iframeSrc = useMemo(() => {
    return (
      iframeUrl ??
      getIframeSrc({
        amexAccepted,
        toastwebBaseUri,
        shortUrl,
        color
      })
    )
  }, [amexAccepted, color, shortUrl, toastwebBaseUri, iframeUrl])

  useMessage({
    origin: new URL(iframeSrc).origin,
    messageHandlers: new Map<string, (data: any) => void>([
      ['CC_VALID', onChange],
      ['CC_INVALID', onError],
      ['IFRAME_HEIGHT', (data: number) => setHeight(data)]
    ])
  })

  useEffect(() => {
    if (!isVisible) {
      setHeight(0)
    }
  }, [isVisible])

  if (!isVisible) {
    return null
  }

  return (
    <>
      {height === 0 && (
        <MerryGoRound
          // @ts-ignore
          testId='cc-iframe-loader'
        />
      )}
      <iframe
        title='Credit Card Form'
        id='credit_card_iframe'
        data-testid='credit-card-iframe'
        frameBorder='0'
        scrolling='no'
        height={`${height}px`}
        width='100%'
        src={iframeSrc}
      />
    </>
  )
}

import * as React from 'react'
import { z } from 'zod'

/**
 * Zod Utils
 */
const jsonSerializableSchema = (function () {
  const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
  type Literal = z.infer<typeof literalSchema>
  type Json = Literal | { [key: string]: Json } | Json[]
  const jsonSchema: z.ZodType<Json> = z.lazy(() =>
    z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
  )
  return jsonSchema
})()

/**
 * Result-like type to help with type-safe
 * data-transformations
 */
type SuccessOrError<TData = any> =
  | {
      success: true
      data: TData
    }
  | {
      success: false
      error: Error
    }
/**
 * Safely parses the JSON string payload with strong type safety.
 * Uses a discriminated result-like object for error-handling
 */
function safeParsedAndTyped<TSchema extends z.ZodObject<any>>(
  event: MessageEvent,
  messageDataSchema: TSchema
): SuccessOrError<z.infer<TSchema>> {
  let jsonData: any
  try {
    jsonData = JSON.parse(event.data)
  } catch (jsonParseError) {
    return { success: false, error: jsonParseError as SyntaxError }
  }
  return messageDataSchema.safeParse(jsonData)
}

/**
 * Returns a stable Map across React renders to hold onto values.
 * Avoids requiring consumers to stabilize Map objects
 * themselves.
 *
 * Intended for expensive reactive useEffects where we don't need
 * to react to the changing content of the map itself.
 */
function useStableMap(map: Map<any, any>) {
  const handlersRef = React.useRef(new Map(Array.from(map.entries())))
  React.useEffect(() => {
    const refMap = handlersRef.current
    // overwrite/update all new handlers
    for (const [key, handler] of map) {
      refMap.set(key, handler)
    }
    // delete all stale/removed handlers
    for (const [key] of refMap) {
      if (!map.get(key)) {
        refMap.delete(key)
      }
    }
  }, [map])
  return handlersRef.current
}

export type UseMessageParams = {
  origin: string
  messageHandlers: Map<string, (data: any) => void>
  onOriginError?(unexpectedOrigin: string): void
  onParsingError?(err: Error): void
}
/**
 * React hook for setting up message-passing
 * callback with messages from a window.
 *
 * Expects messages as JSON strings in the shape:
 * { name: string, data: any }
 */
export const useMessage = ({
  origin,
  messageHandlers,
  onOriginError,
  onParsingError
}: UseMessageParams) => {
  const onOriginErrorRef = React.useRef(onOriginError)
  const onParsingErrorRef = React.useRef(onParsingError)
  onOriginErrorRef.current = onOriginError
  onParsingErrorRef.current = onParsingError

  const stableHandlers = useStableMap(messageHandlers)
  React.useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (event.origin !== origin) {
        onOriginErrorRef.current?.(event.origin)
        return
      }

      const messageData = safeParsedAndTyped(
        event,
        z.object({
          name: z.string(),
          data: z.optional(jsonSerializableSchema)
        })
      )
      if (!messageData.success) {
        onParsingErrorRef.current?.(messageData.error)
        return
      }

      const { name, data } = messageData.data
      const handler = stableHandlers.get(name)
      if (handler) {
        handler(data)
      }
    }

    window.addEventListener('message', receiveMessage, false)
    return () => {
      window.removeEventListener('message', receiveMessage, false)
    }
  }, [stableHandlers, origin])
}

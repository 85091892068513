import React, { Fragment } from 'react'
import CardIcon from '../assets/card-icon.svg'
import AmexIcon from '../assets/credit-card-brands/amex-icon.svg'
import DiscoverIcon from '../assets/credit-card-brands/discover-icon.svg'
import MastercardIcon from '../assets/credit-card-brands/mastercard-icon.svg'
import VisaIcon from '../assets/credit-card-brands/visa-icon.svg'
import ApplePayIcon from '../assets/apple-pay.svg'
import { RadioButton } from '@toasttab/buffet-pui-radio-group'
import { Badge } from '@toasttab/buffet-pui-badge'
import { PaymentMethodKey } from '../../shared-types/index'

const { ToastSavedCard } = PaymentMethodKey

const getCardLogo = (card: string) => {
  let logo
  switch (card) {
    case 'AMEX':
      logo = <AmexIcon />
      break
    case 'DISCOVER':
      logo = <DiscoverIcon />
      break
    case 'MASTERCARD':
      logo = <MastercardIcon />
      break
    case 'VISA':
      logo = <VisaIcon />
      break
    case 'APPLE_PAY_PREAUTH':
      logo = <ApplePayIcon />
      break
    default:
      logo = <CardIcon />
  }
  return logo
}

export const getBadgeCopy = (card: SavedCreditCardPaymentMethod) => {
  if (card.isPreauthed) return 'TAB LINKED'
  if (card.isPrimary) return 'PRIMARY'
  return null
}

export const getDescription = (
  card: SavedCreditCardPaymentMethod,
  amexAccepted: boolean
) => {
  const { maskedPan, expirationMonth, expirationYear, expired, cardType } = card

  const amexMessage =
    !amexAccepted && cardType === 'AMEX'
      ? ' \u00B7 AmEx unavailable at this restaurant'
      : null

  if (cardType === 'APPLE_PAY_PREAUTH') {
    return <div className='font-semibold type-subhead'>Apple Pay</div>
  }
  return (
    <div>
      <p
        data-test-id='saved-card-masked-num'
        className={`type-subhead font-semibold`}
      >
        {maskedPan.replace(/XXXX-/, '**** ')}
      </p>
      <p
        className={`type-subhead ${
          expired ? 'text-error font-semibold' : 'text-secondary'
        }`}
      >
        {expired ? 'Expired' : 'Expires'} {expirationMonth}/{expirationYear}{' '}
        {amexMessage}
      </p>
    </div>
  )
}

export type SavedCreditCardPaymentMethod = {
  cardType: string
  expirationMonth: string
  expirationYear: string
  guid: string
  isPrimary: boolean
  maskedPan: string
  expired: boolean
  isPreauthed?: boolean
}

export type SavedCardOptionsProps = {
  cards: SavedCreditCardPaymentMethod[]
  handleRadioChange: (
    e: { target: { value: PaymentMethodKey } },
    savedCardGuid: string
  ) => void
  selectedPaymentState: any
  amexAccepted: boolean
}

export function SavedCardOptions({
  cards,
  selectedPaymentState,
  handleRadioChange,
  amexAccepted
}: SavedCardOptionsProps) {
  return (
    <>
      {cards?.map((card) => {
        const { cardType, guid, expired } = card
        const badgeCopy = getBadgeCopy(card)
        const isAmexDisabled = cardType === 'AMEX' && !amexAccepted
        return (
          <Fragment key={`saved-card-${card.guid}`}>
            <RadioButton
              data-testid={`radio-option-ToastSavedCard`}
              containerClassName='w-100 items-center h-20'
              disabled={expired || isAmexDisabled}
              label={
                <div className='flex items-center w-100'>
                  <div className='flex items-center justify-center w-12 mr-3'>
                    {getCardLogo(cardType)}
                  </div>
                  {getDescription(card, amexAccepted)}
                  {badgeCopy && (
                    <div className='absolute right-0'>
                      <Badge>{badgeCopy}</Badge>
                    </div>
                  )}
                </div>
              }
              name='select-payment-method'
              value={ToastSavedCard}
              checked={
                ToastSavedCard ===
                  selectedPaymentState?.selectedPaymentMethod &&
                selectedPaymentState?.data?.savedCardGuid === guid
              }
              onChange={(e: { target: { value: PaymentMethodKey } }) =>
                handleRadioChange(e, guid)
              }
            />
            <hr style={{ borderBottom: '0.75px' }} />
          </Fragment>
        )
      })}
    </>
  )
}

/*global ApplePayJS */
export type PaymentRequestDetailItem = {
  /**
   * Number as a float, two-decimal currency unit
   * (e.g. 1.00 == $1.00)
   */
  amount: number
  label: string
  status?: 'final' | 'pending'
  /**
   * Enum string of a display item.
   *
   * Options:
   *
   * - `LINE_ITEM`:
   *   Regular line item.
   *
   * - `SUBTOTAL`:
   *   Subtotal of all regular items.
   *
   * - `TAX`:
   *   Item for the collected tax.
   *
   * - `DISCOUNT`:
   *   Item for a discount.
   *
   * - `SHIPPING_OPTION`:
   *   Item for shipping option.
   */
  type: 'LINE_ITEM' | 'SUBTOTAL' | 'TAX' | 'DISCOUNT' | 'SHIPPING_OPTION'
}

type PaymentRequest = {
  displayDetails: PaymentRequestDetailItem[]
  total: PaymentRequestDetailItem
  merchantDetails: {
    merchantName: string
    merchantIdentifier: string
  }
}

type ErrorLike = {
  message?: string
  reason?: string
  /**
   * Include complete error object for logging
   */
  error?: any
}

export type InitPaymentFlow<
  TPaymentResponse extends PaymentResponse = PaymentResponse
> = (params: {
  paymentRequest: PaymentRequest
  onPrepaymentFailed: (errorLike?: ErrorLike) => void
  onCompletePaymentFailed: (errorLike?: ErrorLike) => void
  onPrepaymentCanceled: (cancelMeta?: { reason?: string }) => void
  completePayment: (params: {
    paymentResponse: TPaymentResponse
  }) => Promise<void>
}) => Promise<void>

export type TokenizedToastEncryptedNewCardDetails = {
  paymentMethod: 'ToastEncryptedNewCard'
  data: {
    encryptedCard: {
      encryptionKeyId: string
      cardFirst6: string
      cardLast4: string
      zipCode: string
      expMonth: string
      expYear: string
    }
    saveCard?: boolean
  }
}

export type TokenizedGooglePayDetails = {
  paymentMethod: 'GooglePay'
  data: {
    googlePayToken: string
    email?: string
    billingAddress: {
      name?: string
      postalCode: string
      countryCode: string
      phoneNumber?: string
      address1?: string
      address2?: string
      address3?: string
      locality?: string
      administrativeArea?: string
      sortingCode?: string
    }
  }
}

export type TokenizedToastSavedCardDetails = {
  paymentMethod: 'ToastSavedCard'
  data: {
    savedCardGuid: string
  }
}

export enum GooglePayEnvironments {
  PRODUCTION = 'PRODUCTION',
  TEST = 'TEST'
}

export type TokenizedApplePayDetails = {
  paymentMethod: 'ApplePay'
  data: {
    /**
     * The entirety of the payment authorization object
     * from Apple Pay
     */
    pkPaymentToken: ApplePayJS.ApplePayPayment
    email: string
  }
}

type PaymentResponse =
  | TokenizedToastEncryptedNewCardDetails
  | TokenizedGooglePayDetails
  | TokenizedToastSavedCardDetails
  | TokenizedApplePayDetails

import * as React from 'react'
import { PaymentMethodKey } from '../../../shared-types'

const { GooglePay, ApplePay } = PaymentMethodKey

const methodsRequiringDisclaimer = [GooglePay, ApplePay]

export const ThirdPartyDisclaimer = ({
  selectedPaymentMethod
}: {
  selectedPaymentMethod: PaymentMethodKey | null
}) => {
  if (!selectedPaymentMethod) return null

  if (methodsRequiringDisclaimer.includes(selectedPaymentMethod)) {
    return (
      <div>
        <hr style={{ borderBottom: '0.75px' }} />
        <div
          data-testid='disclaimer-text'
          className='mx-5 mt-5 text-secondary type-caption'
        >
          We will use your&nbsp;
          {selectedPaymentMethod === ApplePay ? 'Apple' : 'Google'} Pay
          information to provide digital receipts and order status updates. You
          may also receive marketing messages from Toast and restaurants you
          visit, unless you unsubscribe.
        </div>
      </div>
    )
  }

  return null
}

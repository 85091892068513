import {
  InitPaymentFlow,
  TokenizedToastEncryptedNewCardDetails
} from '../types'

export type ToastEncryptedNewCardRequiredData = {
  encryptedCard: {
    encryptionKeyId: string
    cardFirst6: string
    cardLast4: string
    zipCode: string
    expMonth: string
    expYear: string
  }
  saveCard?: boolean
}

export const initToastEncryptedNewCardFlow = (
  data: ToastEncryptedNewCardRequiredData
): InitPaymentFlow<TokenizedToastEncryptedNewCardDetails> => {
  return async function initPaymentFlow({
    completePayment,
    onCompletePaymentFailed
  }) {
    await completePayment({
      paymentResponse: {
        paymentMethod: 'ToastEncryptedNewCard',
        data: {
          encryptedCard: data.encryptedCard,
          saveCard: data.saveCard
        }
      }
    }).catch((e) => {
      onCompletePaymentFailed(e as Error)
    })
  }
}

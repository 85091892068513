import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { CreditCard } from '../../new-credit-card-input'
import { useDigitalPaymentsStore } from '../../digital-payments-store'
import { SelectedPaymentState } from '../../digital-payments-store/types'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useGooglePayAvailability } from '../../google-pay-utils/use-google-pay-availability'
import { usePreserveDeepEquals } from '../../helpers/use-preserve-deep-equals'
import GooglePayIcon from '../assets/google-pay-mark.svg'
import Click2PayLogo from '../assets/click-2-pay-logo.svg'
import CardIcon from '../assets/card-icon.svg'
import NewCardIcon from '../assets/credit-card-brands/new-card-icon.svg'
import ApplePayIcon from '../assets/apple-pay.svg'
import { RadioButton } from '@toasttab/buffet-pui-radio-group'
import { GooglePayEnvironments } from '../../payment-flows/types'
import { PaymentMethodKey } from '../../shared-types/index'
import { useApplePayAvailability } from '../../apple-pay-utils/use-apple-pay-availability'
import {
  SavedCardOptions,
  SavedCreditCardPaymentMethod
} from './SavedCardOptions'
import { SaveNewCardCheckbox } from './SaveNewCardCheckbox'
import { ThirdPartyDisclaimer } from './ThirdPartyDisclaimer/ThirdPartyDisclaimer'

const {
  ToastEncryptedNewCard,
  GooglePay,
  ToastSavedCard,
  ApplePay,
  Click2Pay
} = PaymentMethodKey

const ErrorBoundaryFallBack = (props: FallbackProps) => {
  return (
    <Alert title='Unexpected Error' variant='error'>
      <div className='text-link' onClick={props.resetErrorBoundary}>
        Retry
      </div>
      <div className='text-error'>{props.error}</div>
    </Alert>
  )
}

export type DigitalPaymentSelectorUIProps = {
  googlePayParams?: {
    merchantConfig: {
      merchantName: string
      merchantId: string
    }
    environment: GooglePayEnvironments
    billingAddressOptions?: {
      /**
       * Whether a billing address requires a phone number
       * for payment fulfillment. Defaults to false.
       */
      billingPhoneRequired: boolean
    }
  }
  applePayParams?: {
    getMerchantSession(validationURL: string): Promise<any>
  }
  logError(...params: any[]): void
  toastConfig: {
    /**
     * @deprecated
     */
    shortUrl: string
    /**
     * @deprecated
     */
    toastwebBaseUri: string
    /**
     * Takes precedence over deprecated shortUrl/baseUrl method
     * if present
     */
    iframeUrl?: string
  }
  creditCardConfig: {
    amexAccepted: boolean
  }
  enabledMethods: Set<PaymentMethodKey>
  onSelectedPaymentMethodChanged?: (
    selectedPaymentMethod: SelectedPaymentState
  ) => void
  element?: {
    contents: React.ReactElement
    paymentMethod: PaymentMethodKey
  }
  savedCCPaymentMethods?: SavedCreditCardPaymentMethod[]
  authenticated: boolean
}

const useEvaluateEnabledMethods = (params: {
  enabledMethods: DigitalPaymentSelectorUIProps['enabledMethods']
  environment:
    | Required<DigitalPaymentSelectorUIProps>['googlePayParams']['environment']
    | undefined
  creditCardConfig: DigitalPaymentSelectorUIProps['creditCardConfig']
  savedCCPaymentMethods: DigitalPaymentSelectorUIProps['savedCCPaymentMethods']
  logError: DigitalPaymentSelectorUIProps['logError']
}): PaymentMethodKey[] => {
  const googlePayAvailability = useGooglePayAvailability(
    {
      creditCardConfig: params.creditCardConfig,
      environment: params.environment ?? GooglePayEnvironments.TEST,
      onError: params.logError
    },
    {
      skip: !params.enabledMethods.has(GooglePay) || !params.environment
    }
  )
  const { isDeviceApplePayCapable } = useApplePayAvailability()
  const stableEnabledMethods = usePreserveDeepEquals(params.enabledMethods)
  const numberOfSavedCCs = params.savedCCPaymentMethods?.length ?? 0
  return useMemo(() => {
    const set = new Set<PaymentMethodKey>()
    if (stableEnabledMethods.has(ApplePay) && isDeviceApplePayCapable) {
      set.add(ApplePay)
    }
    if (
      stableEnabledMethods.has(GooglePay) &&
      googlePayAvailability.state === 'loaded' &&
      googlePayAvailability.availabilityInfo.isReadyToPay.result
    ) {
      set.add(GooglePay)
    }
    // do not allow saved card as an eligible method if no saved cards available
    if (stableEnabledMethods.has(ToastSavedCard) && numberOfSavedCCs > 0) {
      set.add(ToastSavedCard)
    }
    if (stableEnabledMethods.has(Click2Pay)) {
      set.add(Click2Pay)
    }
    if (stableEnabledMethods.has(ToastEncryptedNewCard)) {
      set.add(ToastEncryptedNewCard)
    }
    return Array.from(set)
  }, [
    googlePayAvailability,
    stableEnabledMethods,
    isDeviceApplePayCapable,
    numberOfSavedCCs
  ])
}

export function DigitalPaymentSelectorUI({
  googlePayParams,
  applePayParams,
  creditCardConfig,
  enabledMethods: _enabledMethods,
  toastConfig,
  onSelectedPaymentMethodChanged,
  logError,
  element,
  savedCCPaymentMethods,
  authenticated
}: DigitalPaymentSelectorUIProps) {
  const [isSaveCardChecked, setIsSaveCardChecked] = useState(false)

  const setValidState = useDigitalPaymentsStore(
    (s) => s.actions.setValidSelectedPayment
  )
  const setInvalid = useDigitalPaymentsStore(
    (s) => s.actions.setInvalidSelectedPayment
  )
  const selectedPaymentState = useDigitalPaymentsStore(
    (s) => s.selectedPaymentState
  )
  const restorePreviousState = useDigitalPaymentsStore(
    (s) => s.actions.restorePaymentMethodData
  )

  const onSelectedPaymentMethodChangedRef = useRef(
    onSelectedPaymentMethodChanged
  )
  onSelectedPaymentMethodChangedRef.current = onSelectedPaymentMethodChanged

  const evaluatedEnabledMethods = useEvaluateEnabledMethods({
    enabledMethods: _enabledMethods,
    creditCardConfig,
    environment: googlePayParams?.environment,
    savedCCPaymentMethods,
    logError
  })

  useEffect(() => {
    // in order to update parent form or handle a synchronization callback as-needed
    onSelectedPaymentMethodChangedRef.current?.(selectedPaymentState)
  }, [selectedPaymentState])

  const handleRadioChange = (
    e: { target: { value: PaymentMethodKey } },
    savedCardGuid?: string
  ) => {
    const { value } = e.target

    if (value === ToastEncryptedNewCard) {
      restorePreviousState({ methodKey: ToastEncryptedNewCard })
    } else if (value === GooglePay) {
      if (!googlePayParams) return
      setValidState({
        methodKey: GooglePay,
        requiredPaymentFlowData: {
          amexAccepted: creditCardConfig.amexAccepted,
          environment: googlePayParams.environment,
          merchantId: googlePayParams.merchantConfig.merchantId,
          merchantName: googlePayParams.merchantConfig.merchantName,
          billingAddressOptions: {
            billingPhoneRequired:
              googlePayParams.billingAddressOptions?.billingPhoneRequired ??
              false
          }
        }
      })
    } else if (value === ApplePay) {
      if (!applePayParams) return
      setValidState({
        methodKey: ApplePay,
        requiredPaymentFlowData: {
          getMerchantSession: applePayParams.getMerchantSession,
          amexAccepted: creditCardConfig.amexAccepted
        }
      })
    } else if (value === ToastSavedCard) {
      if (!savedCardGuid) return
      setValidState({
        methodKey: ToastSavedCard,
        requiredPaymentFlowData: {
          savedCardGuid
        }
      })
    } else if (value === Click2Pay) {
      setValidState({
        methodKey: Click2Pay,
        requiredPaymentFlowData: null
      })
    }
  }

  /**
   * Select the first evaluated payment method, unless preauthorized card is present.
   * If it changes, then re-select the default.
   *
   * Note: Currently does not preserve the user choice if
   * they made a selection before new "firstMethod"
   * was evaluated
   */

  const preauthCard = savedCCPaymentMethods?.find((card) => card.isPreauthed)
  const firstMethod = preauthCard
    ? PaymentMethodKey.ToastSavedCard
    : evaluatedEnabledMethods[0]

  const primarySavedCardGuid =
    savedCCPaymentMethods?.find((pm) => pm.isPrimary)?.guid ??
    savedCCPaymentMethods?.[0]?.guid
  const stableHandleRadioRef = useRef(handleRadioChange)
  stableHandleRadioRef.current = handleRadioChange
  useEffect(() => {
    const handleChange = stableHandleRadioRef.current
    if (firstMethod === ToastSavedCard) {
      handleChange(
        { target: { value: firstMethod } },
        primarySavedCardGuid! // we can assume this to always be present if ToastSavedCard is enabled
      )
    } else if (firstMethod) {
      handleChange({ target: { value: firstMethod } })
    }
  }, [firstMethod, primarySavedCardGuid])

  const radioOpts: Record<
    PaymentMethodKey,
    { label: string; asset: React.ReactNode } | null
  > = {
    ToastEncryptedNewCard: {
      label: `${authenticated ? 'Add d' : 'D'}ebit or credit card`,
      asset: authenticated ? <NewCardIcon /> : <CardIcon />
    },
    GooglePay: {
      label: 'Google Pay',
      asset: <GooglePayIcon />
    },
    ApplePay: {
      label: 'Apple Pay',
      asset: <ApplePayIcon />
    },
    ToastSavedCard: null,
    Click2Pay: {
      label: 'Click to Pay',
      asset: <Click2PayLogo />
    }
  }

  const showNewCCInput =
    evaluatedEnabledMethods.includes(ToastEncryptedNewCard) &&
    (selectedPaymentState.selectedPaymentMethod === ToastEncryptedNewCard ||
      evaluatedEnabledMethods.length === 1)
  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallBack}
      onError={logError}
      onReset={useDigitalPaymentsStore.getState().reset}
    >
      <div data-testid='digital-payment-selector-ui'>
        {evaluatedEnabledMethods.length > 1 &&
          evaluatedEnabledMethods.map((method, i) => {
            if (method === ToastSavedCard) {
              if (!savedCCPaymentMethods?.length) return null
              return (
                <Fragment key={`radio-option-${method}`}>
                  <SavedCardOptions
                    amexAccepted={creditCardConfig.amexAccepted}
                    cards={savedCCPaymentMethods}
                    handleRadioChange={handleRadioChange}
                    selectedPaymentState={selectedPaymentState}
                  />
                </Fragment>
              )
            }

            const radioOptValues = radioOpts[method]
            if (!radioOptValues) return null

            return (
              <div key={`radio-option-${method}`}>
                <RadioButton
                  data-testid={`radio-option-${method}`}
                  containerClassName='w-100 items-center h-20'
                  label={
                    <div className='flex items-center w-100'>
                      <div className='flex items-center justify-center w-12 mr-3'>
                        {radioOptValues.asset}
                      </div>
                      <div className='font-semibold type-subhead'>
                        {radioOptValues.label}
                      </div>
                    </div>
                  }
                  name='select-payment-method'
                  value={method}
                  checked={
                    method === selectedPaymentState.selectedPaymentMethod
                  }
                  onChange={handleRadioChange}
                />
                {element?.paymentMethod === method && (
                  <div
                    data-testid='ad-element-container'
                    className='-mt-6'
                    onClick={() => {
                      const e = { target: { value: method } }
                      handleRadioChange(e)
                    }}
                  >
                    {element.contents}
                  </div>
                )}
                {/* lines between elements */}
                {i + 1 < evaluatedEnabledMethods.length && (
                  <hr style={{ borderBottom: '0.75px' }} />
                )}
              </div>
            )
          })}
        {evaluatedEnabledMethods.includes(ToastEncryptedNewCard) && (
          <div data-testid='toast-encrypted-new-card'>
            <CreditCard
              color='#183DA3'
              amexAccepted={creditCardConfig.amexAccepted}
              onError={() => setInvalid({ methodKey: ToastEncryptedNewCard })}
              onChange={(newEncryptedCard) => {
                setValidState({
                  methodKey: ToastEncryptedNewCard,
                  requiredPaymentFlowData: {
                    encryptedCard: newEncryptedCard,
                    saveCard: authenticated ? isSaveCardChecked : undefined
                  }
                })
              }}
              shortUrl={toastConfig.shortUrl}
              toastwebBaseUri={toastConfig.toastwebBaseUri}
              iframeUrl={toastConfig.iframeUrl}
              isVisible={showNewCCInput}
            />
            <SaveNewCardCheckbox
              authenticated={authenticated}
              isChecked={isSaveCardChecked}
              setIsChecked={setIsSaveCardChecked}
              setValidState={setValidState}
              selectedPaymentState={selectedPaymentState}
            />
          </div>
        )}
        <ThirdPartyDisclaimer
          selectedPaymentMethod={selectedPaymentState.selectedPaymentMethod}
        />
      </div>
    </ErrorBoundary>
  )
}

import { InitPaymentFlow, TokenizedToastSavedCardDetails } from '../types'

export type ToastSavedCardRequiredData = {
  savedCardGuid: string
}

export const createInitToastSavedCardFlow = (
  data: ToastSavedCardRequiredData
): InitPaymentFlow<TokenizedToastSavedCardDetails> => {
  return async function initPaymentFlow({
    completePayment,
    onCompletePaymentFailed
  }) {
    await completePayment({
      paymentResponse: {
        paymentMethod: 'ToastSavedCard',
        data: {
          savedCardGuid: data.savedCardGuid
        }
      }
    }).catch((e) => {
      onCompletePaymentFailed(e as Error)
    })
  }
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { getGPayIsReadyToPay } from '.'
import { usePreserveDeepEquals } from '../helpers/use-preserve-deep-equals'

type AvailabilityState =
  | {
      state: 'loading'
    }
  | {
      state: 'loaded'
      availabilityInfo: {
        isReadyToPay: {
          result: boolean
        }
      }
    }
  | {
      state: 'init'
    }
  | {
      state: 'error'
    }

export const useGooglePayAvailability = (
  params: Parameters<typeof getGPayIsReadyToPay>[0],
  options?: {
    skip?: boolean
  }
) => {
  const [availabilityState, setState] = useState<AvailabilityState>({
    state: 'init'
  })

  const onErrorRef = useRef(params.onError)
  onErrorRef.current = params.onError
  const stableOnError = useCallback((e: unknown) => {
    onErrorRef.current(e)
  }, [])
  const stableParams = usePreserveDeepEquals({
    ...params,
    onError: stableOnError
  })
  useEffect(() => {
    if (options?.skip) return
    setState({ state: 'loading' })
    getGPayIsReadyToPay(stableParams)
      .then((value) => {
        setState({
          state: 'loaded',
          availabilityInfo: {
            isReadyToPay: {
              result: value.isReadyToPay.result
            }
          }
        })
      })
      .catch((e) => {
        console.error(e)
        setState({ state: 'error' })
      })
  }, [stableParams, options?.skip])

  return availabilityState
}

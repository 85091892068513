import React from 'react'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { PaymentMethodKey } from '../../shared-types/index'
import { SelectedPaymentState } from '../../digital-payments-store/types'

type SaveNewCardCheckboxProps = {
  authenticated: boolean
  isChecked: boolean
  setIsChecked: (arg1: boolean) => void
  setValidState: (params: any) => void
  selectedPaymentState: SelectedPaymentState
}

const LabelComponent = () => (
  <div className='flex'>
    <div className='mr-1'>Remember this card</div>
    <InfoTooltip cropToIcon className='border-0 bg-transparent'>
      By selecting "Remember this card" and clicking on "Place Order" you are
      authorizing Toast, Inc. to store your credit card information provided
      above (the “Card"), and to charge your Card for future purchases initiated
      by you. You may update your Card information or cancel this authorization
      any time via the "My Account" page of the Toast Online Ordering website.
      This authorization will remain in effect until updated or canceled.
    </InfoTooltip>
  </div>
)

export function SaveNewCardCheckbox({
  authenticated,
  isChecked,
  setIsChecked,
  setValidState,
  selectedPaymentState
}: SaveNewCardCheckboxProps) {
  const isNewCardEntry =
    selectedPaymentState.selectedPaymentMethod ===
    PaymentMethodKey.ToastEncryptedNewCard

  if (!authenticated || !isNewCardEntry) return null

  const handleChange = () => {
    const newCheckedState = !isChecked
    // if the state is valid, we need to update the requiredPaymentFlowData with the new value
    // if the state is invalid, the parent component will update the value once the state is valid
    if (selectedPaymentState.state === 'valid' && isNewCardEntry) {
      setValidState({
        methodKey: PaymentMethodKey.ToastEncryptedNewCard,
        requiredPaymentFlowData: {
          encryptedCard: selectedPaymentState.data.encryptedCard,
          saveCard: newCheckedState
        }
      })
    }
    setIsChecked(newCheckedState)
  }

  return (
    <Checkbox
      data-testid='save-new-card-checkbox'
      checked={isChecked}
      label={<LabelComponent />}
      onChange={handleChange}
    />
  )
}

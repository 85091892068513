import {
  RequiredDataMap,
  SelectedPaymentState,
  SupportedPaymentType
} from '../types'
import { create } from 'zustand'
import { PaymentMethodKey } from '../../shared-types/index'

const {
  ToastEncryptedNewCard,
  GooglePay,
  ApplePay,
  ToastSavedCard,
  Click2Pay
} = PaymentMethodKey

type ValidSelectedPaymentParams<TMethodKey extends SupportedPaymentType> = {
  methodKey: TMethodKey
  requiredPaymentFlowData: RequiredDataMap[TMethodKey]
}

type PaymentActions = {
  setValidSelectedPayment(
    params:
      | ValidSelectedPaymentParams<typeof ToastEncryptedNewCard>
      | ValidSelectedPaymentParams<typeof GooglePay>
      | ValidSelectedPaymentParams<typeof ApplePay>
      | ValidSelectedPaymentParams<typeof ToastSavedCard>
      | ValidSelectedPaymentParams<typeof Click2Pay>
  ): void
  setInvalidSelectedPayment(params: { methodKey: SupportedPaymentType }): void
  restorePaymentMethodData(params: { methodKey: SupportedPaymentType }): void
}

export type PaymentState = {
  selectedPaymentState: SelectedPaymentState
  paymentDataHistory: Partial<RequiredDataMap>
  actions: PaymentActions
  /**
   * Resets to initial state
   */
  reset(): void
}

export const useDigitalPaymentsStore = create<PaymentState>()((set) => {
  const initialSelectedPaymentState: SelectedPaymentState = {
    state: 'init',
    selectedPaymentMethod: null
  }
  return {
    selectedPaymentState: initialSelectedPaymentState,
    actions: {
      restorePaymentMethodData({ methodKey }) {
        set((s) => {
          const previousData = s.paymentDataHistory[methodKey]
          if (!previousData) {
            return {
              selectedPaymentState: {
                state: 'invalid',
                selectedPaymentMethod: methodKey
              }
            }
          }
          return {
            selectedPaymentState: {
              selectedPaymentMethod: methodKey,
              state: 'valid',
              data: previousData
            } as PaymentState['selectedPaymentState']
          }
        })
      },
      setValidSelectedPayment(params) {
        const newRequiredDataMapEntry = {
          [params.methodKey]: params.requiredPaymentFlowData
        }
        if (params.methodKey === ToastEncryptedNewCard) {
          set((s) => {
            return {
              paymentDataHistory: {
                ...s.paymentDataHistory,
                ...newRequiredDataMapEntry
              },
              selectedPaymentState: {
                state: 'valid',
                selectedPaymentMethod: ToastEncryptedNewCard,
                data: params.requiredPaymentFlowData
              }
            }
          })
        } else if (params.methodKey === GooglePay) {
          set({
            selectedPaymentState: {
              selectedPaymentMethod: GooglePay,
              state: 'valid',
              data: params.requiredPaymentFlowData
            }
          })
        } else if (params.methodKey === ApplePay) {
          set({
            selectedPaymentState: {
              selectedPaymentMethod: ApplePay,
              data: params.requiredPaymentFlowData,
              state: 'valid'
            }
          })
        } else if (params.methodKey === ToastSavedCard) {
          set({
            selectedPaymentState: {
              selectedPaymentMethod: ToastSavedCard,
              state: 'valid',
              data: params.requiredPaymentFlowData
            }
          })
        } else if (params.methodKey === Click2Pay) {
          set({
            selectedPaymentState: {
              selectedPaymentMethod: Click2Pay,
              state: 'valid',
              data: params.requiredPaymentFlowData
            }
          })
        }
      },
      setInvalidSelectedPayment(params) {
        set({
          selectedPaymentState: {
            state: 'invalid',
            selectedPaymentMethod: params.methodKey
          }
        })
      }
    },
    paymentDataHistory: {},
    reset() {
      set({
        paymentDataHistory: {},
        selectedPaymentState: initialSelectedPaymentState
      })
    }
  }
})

/*global google */
import { GooglePayEnvironments, InitPaymentFlow } from '../payment-flows/types'

declare global {
  interface Window {
    google?: typeof google
  }
}

/**
 * A singleton Promise that initializes google pay window api on page load.
 * This is to help load the required third-party library eagerly.
 *
 * In order to ensure it's handled at the top-level, all
 * rejections are caught and simply logged to the console.
 *
 * The downside here is that we may lose some metrics visibility into
 * details of the errors that are occurring on initialization for guests.
 *
 * If fails to load, will resolve to 'undefined'. All consumers
 * should handle accordingly.
 *
 */
const googlePaymentsApi = new Promise<typeof google.payments.api | undefined>(
  (res, rej) => {
    try {
      const script = document.createElement('script')
      script.src = 'https://pay.google.com/gp/p/js/pay.js'
      script.async = true
      script.onerror = rej
      script.onload = () => {
        const paymentsApiClient = window.google?.payments?.api
        if (paymentsApiClient) {
          res(paymentsApiClient)
        } else {
          rej(
            new Error(
              'Unable to successfully create Google Pay client. Google Pay undefined.'
            )
          )
        }
      }
      document.body.append(script)
    } catch (e) {
      rej(e)
    }
  }
).catch((e) => {
  console.error(e)
  return undefined
})

/**
 * Resolves to a Google PaymentsClient instance with the given options.
 *
 * Rejects if problem with client construction.
 *
 * Resolves undefined
 * if external dependencies for third-party client library fails to load.
 */
export const createGoogleClient = async (
  options: google.payments.api.PaymentOptions
): Promise<google.payments.api.PaymentsClient | undefined> => {
  const api = await googlePaymentsApi
  if (!api) return undefined
  return new api.PaymentsClient(options)
}

/**
 * Resolves to a GooglePay client + a determination of whether
 * the user isReadyToPay.
 *
 * If fails, returns a default fallback that assumes the user
 * is not able to pay.
 */
export const getGPayIsReadyToPay = async (params: {
  environment: GooglePayEnvironments
  creditCardConfig: {
    amexAccepted: boolean
  }
  billingOptions?: {
    billingPhoneRequired: boolean
  }
  onError(e: unknown): void
}): Promise<{
  isReadyToPay: google.payments.api.IsReadyToPayResponse
  client: google.payments.api.PaymentsClient | undefined
}> => {
  const client = await createGoogleClient({
    environment: params.environment
  }).catch((e) => {
    params.onError(e)
    return undefined
  })

  if (!client) {
    return {
      client: undefined,
      isReadyToPay: {
        result: false
      }
    }
  }

  return client
    .isReadyToPay({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        buildToastGpayPaymentMethods({
          amexAccepted: params.creditCardConfig.amexAccepted,
          phoneNumberRequired:
            params.billingOptions?.billingPhoneRequired ?? false
        }).buildIsReadyToPay()
      ]
    })
    .then((isReadyToPay) => {
      return {
        client,
        isReadyToPay
      }
    })
    .catch((e) => {
      params.onError(e)
      return {
        client,
        isReadyToPay: {
          result: false
        }
      }
    })
}

type PaymentMethodSpecBuilder = {
  buildIsReadyToPay(): google.payments.api.IsReadyToPayPaymentMethodSpecification
  buildWithTokenization(tokenizationParams: {
    gatewayMerchantId: string
  }): google.payments.api.PaymentMethodSpecification
}

export const buildToastGpayPaymentMethods = ({
  amexAccepted,
  phoneNumberRequired
}: {
  amexAccepted: boolean
  phoneNumberRequired: boolean
}): PaymentMethodSpecBuilder => {
  const allowedCardNetworks: google.payments.api.CardNetwork[] = [
    'DISCOVER',
    'JCB',
    'MASTERCARD',
    'VISA'
  ]
  if (amexAccepted) {
    allowedCardNetworks.push('AMEX')
  }

  const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification =
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY'],
        allowedCardNetworks,
        billingAddressRequired: true,
        billingAddressParameters: phoneNumberRequired
          ? {
              phoneNumberRequired: true,
              format: 'MIN'
            }
          : undefined
      }
    }

  return {
    buildIsReadyToPay() {
      return {
        ...baseCardPaymentMethod
      }
    },
    buildWithTokenization({ gatewayMerchantId }) {
      return {
        ...baseCardPaymentMethod,
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'toast',
            gatewayMerchantId
          }
        }
      }
    }
  }
}

export const makeGPayPayment = (params: {
  config: {
    amexAccepted: boolean
    environment: GooglePayEnvironments
    billingAddressRequired: boolean
    emailRequired: boolean
    billingPhoneNumberRequired?: boolean
  }
  paymentRequest: {
    gatewayMerchantId: string
    merchantInfo: google.payments.api.MerchantInfo
    transactionInfo: google.payments.api.TransactionInfo
  }
  performTransaction(
    paymentData: google.payments.api.PaymentData
  ): Promise<void>
  onPrepaymentFailed: Parameters<InitPaymentFlow>[0]['onPrepaymentFailed']
  onPrepaymentCanceled: Parameters<InitPaymentFlow>[0]['onPrepaymentCanceled']
  onCompletePaymentFailed: Parameters<InitPaymentFlow>[0]['onCompletePaymentFailed']
}): void => {
  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
  } as const

  const loadPaymentRequest: google.payments.api.PaymentDataRequest = {
    ...baseRequest,
    allowedPaymentMethods: [
      buildToastGpayPaymentMethods({
        amexAccepted: params.config.amexAccepted,
        phoneNumberRequired: params.config.billingPhoneNumberRequired ?? false
      }).buildWithTokenization({
        gatewayMerchantId: params.paymentRequest.gatewayMerchantId
      })
    ],
    transactionInfo: params.paymentRequest.transactionInfo,
    merchantInfo: params.paymentRequest.merchantInfo,
    emailRequired: true,
    callbackIntents: ['PAYMENT_AUTHORIZATION']
  }

  createGoogleClient({
    environment: params.config.environment,
    merchantInfo: {
      merchantId: params.paymentRequest.merchantInfo.merchantId,
      merchantName: params.paymentRequest.merchantInfo.merchantName
    },
    paymentDataCallbacks: {
      async onPaymentAuthorized(paymentData) {
        const isPaypalMethod = paymentData.paymentMethodData.description
          ?.toLowerCase()
          .includes('paypal')
        if (isPaypalMethod) {
          params.onPrepaymentFailed({
            message:
              'PayPal via Google Pay is not supported. Please select a valid credit card payment method.',
            reason: 'PAYMENT_DATA_INVALID',
            error: new Error(
              'Payment attempt rejected. GPay guest used PayPal.'
            )
          })
          return {
            transactionState: 'ERROR',
            error: {
              intent: 'PAYMENT_AUTHORIZATION',
              message:
                'PayPal is not supported at this time. Please try again with an alternative payment method.',
              reason: 'PAYMENT_DATA_INVALID'
            }
          }
        }
        try {
          await params.performTransaction(paymentData)
          return {
            transactionState: 'SUCCESS'
          }
        } catch (e) {
          // can handle errors within sheet here
          params.onCompletePaymentFailed({
            message: 'Payment attempt failed. Please try again.',
            reason: 'PAYMENT_DATA_INVALID',
            error: e
          })
          return Promise.resolve({
            transactionState: 'ERROR',
            error: {
              intent: 'PAYMENT_AUTHORIZATION',
              message: 'Payment attempt failed. Please try again.',
              reason: 'PAYMENT_DATA_INVALID'
            }
          })
        }
      }
    }
  })
    // handle client creation issues
    .catch((e) => {
      params.onPrepaymentFailed({
        error: e
      })
      return undefined
    })
    .then((client) => client?.loadPaymentData(loadPaymentRequest))
    // handle errors related to usage of the GPay payment sheet
    .catch((e) => {
      function isPaymentsError(
        unknownError: unknown
      ): unknownError is google.payments.api.PaymentsError {
        return 'statusCode' in (e as google.payments.api.PaymentsError)
      }

      if (!isPaymentsError(e)) {
        params.onPrepaymentFailed(e)
        return
      }

      const paymentsError = e
      if (paymentsError.statusCode === 'CANCELED') {
        params.onPrepaymentCanceled({
          reason: paymentsError.statusMessage
        })
      } else {
        params.onPrepaymentFailed({
          message: paymentsError.statusMessage
        })
      }
    })
}

import { makeApplePayPayment } from '../../apple-pay-utils'
import { InitPaymentFlow, TokenizedApplePayDetails } from '../types'

export type ApplePayRequiredData = {
  getMerchantSession(validationURL: string): Promise<any>
  amexAccepted: boolean
}

export const initApplePayFlow = (
  data: ApplePayRequiredData
): InitPaymentFlow<TokenizedApplePayDetails> => {
  return async function initPaymentFlow({
    completePayment,
    onCompletePaymentFailed,
    onPrepaymentCanceled,
    onPrepaymentFailed,
    paymentRequest
  }) {
    const supportedNetworks = ['discover', 'masterCard', 'visa']
    if (data.amexAccepted) {
      supportedNetworks.push('amex')
    }

    return new Promise((res, rej) => {
      makeApplePayPayment({
        applePayRequest: {
          supportedNetworks,
          lineItems: paymentRequest.displayDetails.map((detail) => {
            return {
              amount: detail.amount.toFixed(2),
              label: detail.label,
              type: detail.status
            }
          }),
          countryCode: 'US',
          currencyCode: 'USD',
          merchantCapabilities: ['supports3DS'],
          requiredBillingContactFields: [
            'postalAddress',
            'name',
            'phone',
            'email'
          ],
          requiredShippingContactFields: ['name', 'phone', 'email'],
          total: {
            amount: paymentRequest.total.amount.toFixed(2),
            label: paymentRequest.total.label,
            type: paymentRequest.total.status
          }
          // figure out how to adapt from paymentRequest
        },
        getMerchantSession: data.getMerchantSession,
        onPrepaymentCanceled(_cancelEvent) {
          // we can inspect the cancelEvent more deeply
          // as-needed to handle specific cancellation scenarios.
          onPrepaymentCanceled({
            reason: 'Apple Pay attempt canceled. Please try again.'
          })
          rej(new Error('Apple pay attempt canceled'))
        },
        onCompletePaymentError(e) {
          onCompletePaymentFailed({
            error: e,
            message: e.message
          })
        },
        onPrepaymentError(e) {
          onPrepaymentFailed({
            error: e,
            message: e.message
          })
        },
        performTransaction(paymentObj) {
          return completePayment({
            paymentResponse: {
              paymentMethod: 'ApplePay',
              data: {
                // guaranteed by requiredShippingContactFields
                email: paymentObj.shippingContact?.emailAddress!,
                pkPaymentToken: paymentObj
              }
            }
          })
            .then(res)
            .catch(rej)
        }
      })()
    })
  }
}

import { makeGPayPayment } from '../../google-pay-utils'
import {
  InitPaymentFlow,
  TokenizedGooglePayDetails,
  GooglePayEnvironments
} from '../types'

export type GooglePayRequiredData = {
  amexAccepted: boolean
  merchantId: string
  merchantName: string
  environment: GooglePayEnvironments
  billingAddressOptions?: {
    /**
     * Whether a billing address requires a phone number
     * for payment fulfillment. Defaults to false
     */
    billingPhoneRequired: boolean
  }
}

const GOOGLE_IDENTIFIER_FOR_TOAST = 'BCR2DN4TXCUNVFBA'

export const initGPayFlow = (
  data: GooglePayRequiredData
): InitPaymentFlow<TokenizedGooglePayDetails> => {
  return async function initPaymentFlow({
    completePayment,
    onPrepaymentCanceled,
    onPrepaymentFailed,
    onCompletePaymentFailed,
    paymentRequest
  }) {
    return makeGPayPayment({
      config: {
        amexAccepted: data.amexAccepted,
        environment: data.environment,
        billingAddressRequired: true,
        emailRequired: true,
        billingPhoneNumberRequired:
          data.billingAddressOptions?.billingPhoneRequired ?? false
      },
      onCompletePaymentFailed,
      performTransaction(paymentData) {
        const billingAddress =
          paymentData.paymentMethodData.info?.billingAddress! // safely assumes always here since billingAddressRequired option is hard-coded true

        return completePayment({
          paymentResponse: {
            paymentMethod: 'GooglePay',
            data: {
              googlePayToken:
                paymentData.paymentMethodData.tokenizationData.token,
              email: paymentData.email!, // safely assumes because emailRequired option is hard-coded true
              billingAddress: {
                name: billingAddress.name,
                phoneNumber: billingAddress.phoneNumber,
                ...billingAddress
              }
            }
          }
        }).catch((e) => {
          onCompletePaymentFailed(e as Error)
          // re-throw for consumption by GPay
          throw e
        })
      },
      onPrepaymentCanceled,
      onPrepaymentFailed,
      paymentRequest: {
        gatewayMerchantId: data.merchantId,
        merchantInfo: {
          merchantId: GOOGLE_IDENTIFIER_FOR_TOAST,
          merchantName: data.merchantName
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: paymentRequest.total.amount.toFixed(2).toString(),
          currencyCode: 'USD',
          countryCode: 'US',
          totalPriceLabel: `Amount due`,
          displayItems: paymentRequest.displayDetails.map((detail) => {
            return {
              label: detail.label,
              price: detail.amount.toFixed(2).toString(),
              status: detail.status === 'final' ? 'FINAL' : 'PENDING',
              type: detail.type
            }
          })
        }
      }
    })
  }
}

/*global ApplePaySession,ApplePayJS */

declare global {
  interface Window {
    ApplePaySession?: typeof ApplePaySession
  }
}

export type MakeApplePayPaymentParams = {
  /**
   * Given a validationURL from the apple pay instance,
   * fetches the data for generating a session for the merchant
   */
  getMerchantSession(validationURL: string): Promise<any>
  /**
   * With the encrypted payment information,
   * the function to call to execute the transaction
   */
  performTransaction(applePayPayment: ApplePayJS.ApplePayPayment): Promise<void>
  applePayRequest: ApplePayJS.ApplePayPaymentRequest
  /**
   *  A callback function that is called when the payment UI is dismissed.
   *  Reasons for dismissal can either be due to errors or active user input
   */
  onPrepaymentCanceled(event: ApplePayJS.Event): void
  /**
   *  A callback function that is called when there is an error that occurs in the payment.
   *  sheet prior to the payment completion attempt (i.e. sending authorized token).
   */
  onPrepaymentError(error: Error): void
  /**
   *  A callback function that is called when there is an error due
   *  to performing the transaction
   */
  onCompletePaymentError(error: Error): void
}

/**
 * Setup function that returns a function for
 * initiating an Apple pay payment
 * Utilizing Apple Pay version 6
 *
 * Hides away the details of Apple Pay session creation
 */
export function makeApplePayPayment({
  getMerchantSession,
  performTransaction,
  applePayRequest,
  onPrepaymentCanceled,
  onPrepaymentError,
  onCompletePaymentError
}: MakeApplePayPaymentParams): () => void {
  const ApplePaySessionClass = window.ApplePaySession
  if (!ApplePaySessionClass) {
    throw new Error('Apple Pay session not defined')
  }
  const APPLE_PAY_VERSION = 6
  const session = new ApplePaySessionClass(APPLE_PAY_VERSION, applePayRequest)
  session.onvalidatemerchant = ({
    validationURL
  }: {
    validationURL: string
  }) => {
    if (!validationURL) {
      onPrepaymentError(
        new Error('Unable to obtain validation URL. Please try again.')
      )
      session.completePayment(ApplePaySessionClass.STATUS_FAILURE)
    }
    getMerchantSession(validationURL)
      .then((merchantSession) => {
        session.completeMerchantValidation(merchantSession)
      })
      .catch((e) => {
        console.error(e)
        onPrepaymentError(
          new Error('Unable to fetch merchant session due to an unknown error.')
        )
        session.abort()
      })
  }
  session.onpaymentauthorized = ({
    payment
  }: {
    payment: ApplePayJS.ApplePayPayment
  }) => {
    performTransaction(payment)
      .then(() => {
        session.completePayment(ApplePaySessionClass.STATUS_SUCCESS)
      })
      .catch((e) => {
        session.completePayment(ApplePaySessionClass.STATUS_FAILURE)
        session.abort()
        onCompletePaymentError(e)
      })
  }
  session.oncancel = onPrepaymentCanceled
  return session.begin.bind(session)
}

/**
 * Leaving here un-exposed for future usage.
 * Must be careful with this method because it requires defaulting to Apple Pay
 * if used, as part of Apple Pay TOS.
 *
 * To wit: "Per the Apple Pay on the Web Acceptable Use Guidelines,
 * if you invoke the canMakePaymentsWithActiveCard API and determine that a user has
 * an active card provisioned into Wallet, then Apple Pay must automatically be set as the
 * default or sole payment option on any webpage where payments are accepted, such as the
 * checkout page or product detail page. In all other situations, use canMakePayments instead."
 *
 * See: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778000-canmakepaymentswithactivecard#discussion
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCanApplePayMakePaymentsWithActiveCard = async (params: {
  environment: 'PRODUCTION' | 'TEST'
}) => {
  let merchantIdentifier = 'merchant.com.toasttab.eng.preprod'
  if (params.environment === 'PRODUCTION') {
    merchantIdentifier = 'merchant.com.toasttab.www'
  }
  return Boolean(
    await window?.ApplePaySession?.canMakePaymentsWithActiveCard(
      merchantIdentifier
    )
  )
}

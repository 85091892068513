import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { getSentryConfig } from 'do-cornucopia-root'
import { App } from './app/App'
import './index.css'

const appMode = (window as Record<string, any>).OO_GLOBALS.mode
const sentry = getSentryConfig(appMode)
const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-digital-payments-methods-poc',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: sentry.publicKey,
    projectId: sentry.projectId,
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'digital-payment-methods-poc'

/**
 * Runtime modules
 */

/**
 * Components
 */
export { DigitalPaymentSelectorUI } from './digital-payment-selector-ui'
export { GooglePayButton } from './google-pay-utils/GooglePayButton'

/**
 * State hooks
 */
export { useDigitalSelectedPaymentState } from './digital-payments-store'

/**
 * Payment Flow Functions
 */
export {
  /**
   * @deprecated Renamed to createInitGPayFlow
   * and to be removed in a future version
   */
  initGPayFlow,
  /**
   * @deprecated Renamed to createInitToastEncryptedNewCardFlow
   * and to be removed in a future version
   */
  initToastEncryptedNewCardFlow
} from './payment-flows'
export {
  initGPayFlow as createInitGPayFlow,
  initToastEncryptedNewCardFlow as createInitToastEncryptedNewCardFlow,
  initApplePayFlow as createInitApplePayFlow,
  createInitToastSavedCardFlow
} from './payment-flows'

/**
 * Type definitions
 */
export { InitPaymentFlow, GooglePayEnvironments } from './payment-flows/types'
export { SelectedPaymentState } from './digital-payments-store/types'
export { PaymentMethodKey } from './shared-types'

import React, { useRef, useEffect } from 'react'
import { buildToastGpayPaymentMethods, getGPayIsReadyToPay } from '..'
import { GooglePayEnvironments } from '../../payment-flows/types'

type GooglePayButtonProps = {
  onClick: (event: Event) => void
  onError(e: unknown): void
  environment: GooglePayEnvironments
  creditCardConfig: {
    amexAccepted: boolean
  }
  billingAddressOptions?: {
    /**
     * Whether a billing phone number is required for payment fulfillment.
     */
    billingPhoneRequired: boolean
  }
  containerClassName?: string
  // hardcoded to reduce need for consumers to install type dependencies
  googleButtonOptions?: {
    buttonColor?: 'default' | 'black' | 'white'
    buttonType?:
      | 'book'
      | 'buy'
      | 'checkout'
      | 'donate'
      | 'order'
      | 'pay'
      | 'plain'
      | 'subscribe'
      | 'long'
      | 'short'
    buttonSizeMode?: 'static' | 'fill'
    /**
     * The [ISO
     * 639-1](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes) code
     * representing the desired button language.
     *
     * Supported locales include `en`, `ar`, `bg`, `ca`, `cs`, `da`, `de`,
     * `el`, `es`, `et`, `fi`, `fr`, `hr`, `id`, `it`, `ja`, `ko`, `ms`,
     * `nl`, `no`, `pl`, `pt`, `ru`, `sk`, `sl`, `sr`, `sv`, `th`, `tr`,
     * `uk`, and `zh`.
     *
     * @default browser or operating system language
     */
    buttonLocale?: string
  }
  testId?: string
}

export const GooglePayButton = ({
  onClick,
  onError,
  environment,
  creditCardConfig,
  containerClassName,
  googleButtonOptions,
  billingAddressOptions,
  testId
}: GooglePayButtonProps) => {
  const divRef = useRef<HTMLDivElement | null>(null)

  // use refs to stabilize functions
  const onClickRef = useRef(onClick)
  useEffect(() => {
    onClickRef.current = onClick
  }, [onClick])
  const onErrorRef = useRef(onError)
  useEffect(() => {
    onErrorRef.current = onError
  }, [onError])

  const { buttonColor, buttonType, buttonLocale, buttonSizeMode } =
    googleButtonOptions ?? {}
  const amexAccepted = creditCardConfig.amexAccepted
  useEffect(() => {
    // variable out of scope for DOM cleanup on unmount
    let buttonElement: HTMLElement | null = null
    getGPayIsReadyToPay({
      environment,
      creditCardConfig: {
        amexAccepted
      },
      onError: onErrorRef.current
    }).then(({ client: c, isReadyToPay }) => {
      if (!c || !isReadyToPay.result) return
      const button = c.createButton({
        onClick(...params) {
          return onClickRef.current(...params)
        },
        buttonColor,
        buttonType,
        buttonLocale,
        buttonSizeMode,
        allowedPaymentMethods: [
          buildToastGpayPaymentMethods({
            amexAccepted: amexAccepted,
            phoneNumberRequired:
              billingAddressOptions?.billingPhoneRequired ?? false
          }).buildIsReadyToPay()
        ]
      })
      buttonElement = button
      divRef.current?.appendChild(button)
    })

    return () => {
      if (buttonElement) {
        buttonElement.remove()
      }
    }
  }, [
    environment,
    amexAccepted,
    buttonColor,
    buttonType,
    buttonLocale,
    buttonSizeMode,
    billingAddressOptions?.billingPhoneRequired
  ])

  return (
    <div
      data-testid={testId ?? 'dpm-google-pay'}
      className={containerClassName}
      ref={divRef}
    ></div>
  )
}

import { useDigitalPaymentsStore } from './store'

/**
 * Usage for consumption in apps that only want to read state
 */
export const useDigitalSelectedPaymentState = () => {
  return useDigitalPaymentsStore((s) => s.selectedPaymentState)
}

export { useDigitalPaymentsStore, PaymentState } from './store'
